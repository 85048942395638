import { authApi, cmsApi } from '@/api'

export const createAccount = (params: any) => {
  return authApi.post('api_admin/v1/metax/users/add', params)
}

export const passwordEdit = (params: any) => {
  return authApi.put('api_admin/v1/metax/users/password/edit', params)
}

export const userEdit = (params: any) => {
  return authApi.put('api_admin/v1/metax/users/info/edit', params)
}

export const getUserList = (params?: any) => {
  return authApi.get('api_admin/v1/metax/users/list', params)
}

export const getCreditsInfo = async () => {
  return cmsApi.get(`credits_info`)
}

export const assignCredits = (data: any) => {
  return cmsApi.post('credits_assigns', data)
}

export const getUserCredits = (data: any) => {
  return cmsApi.post('get_user_credits', data)
}

export const getSystemConfig = async (data: any) => {
  return cmsApi.get(`sys_config`, data)
}

export const resetPassword = (params: any) => {
  return authApi.put(`api/v1/metax/users/password/edit`, params)
}

export const adminPasswordEdit = (params: any) => {
  return authApi.put('api_admin/v1/metax/password/edit', params)
}
