import './index.scss'
import { Button, Form, Input, InputNumber, message, Popover, Switch, Upload } from 'antd'
import { RcFile } from 'antd/es/upload'
import { FC, useEffect } from 'react'
import { cmsApi } from '@/api'
import * as Api from '@/api/manage'
import Guide1 from '@/assets/guide-1.webp'
import Guide2 from '@/assets/guide-2.webp'
import Guide3 from '@/assets/guide-3.webp'
import Guide4 from '@/assets/guide-4.webp'
import Guide5 from '@/assets/guide-5.webp'
import Guide6 from '@/assets/guide-6.webp'
import Guide7 from '@/assets/guide-7.webp'
import { UserStore } from '@/global-states'
import { UploadOutlined } from '@ant-design/icons'

const ManageSystem: FC = () => {
  const [form] = Form.useForm()
  const iconUrl = Form.useWatch('ico_url', form)
  const logoImage = Form.useWatch('logo', form)
  const backgroundImage = Form.useWatch('background_image', form)
  const headLogo = Form.useWatch('head_logo', form)
  const promoteImage = Form.useWatch('promote_image', form)
  const enableCreatioCostPerMinute = Form.useWatch('enable_creation_cost_per_minute', form)
  const enableDigitalHumanCost = Form.useWatch('enable_digital_human_cost', form)

  const {
    name,
    display_name,
    welcome_line,
    logo,
    ico_url,
    background_image,
    head_logo,
    keywords,
    description,
    promote_image,
    enable_creation_cost_per_minute,
    creation_cost_per_minute,
    enable_digital_human_cost,
    digital_human_cost,
    icp_filing_no
  } = UserStore.config

  useEffect(() => {
    UserStore.getSystemConfig()
    form.setFieldsValue({
      display_name,
      welcome_line,
      logo,
      ico_url,
      background_image,
      head_logo,
      keywords,
      description,
      promote_image,
      enable_creation_cost_per_minute,
      creation_cost_per_minute,
      enable_digital_human_cost,
      digital_human_cost,
      icp_filing_no
    })
  }, [])

  const formItemLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 }
  }

  const normFile = (e: any) => {
    console.log('Upload event:', e)
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const onFinish = async () => {
    await form.validateFields()
    const { name } = UserStore.config
    const values = form.getFieldsValue()

    if (values.enable_creation_cost_per_minute && !values.creation_cost_per_minute) {
      return message.warning('请输入每分钟积分收费标准')
    }

    if (values.enable_digital_human_cost && !values.digital_human_cost) {
      return message.warning('请输入形象复刻消耗积分')
    }

    await Api.modifySystemConfig(name, {
      ...values,
      update_creation_cost_per_minute: true,
      update_digital_human_cost: true
    })
    message.success('保存成功')
    UserStore.getSystemConfig()
  }

  const uploadFile = async (file: RcFile, key: string) => {
    try {
      const segs = (file.name || '').split(/\./)
      const { upload_url, public_url, content_type } =
        (await cmsApi.post('upload_url', {
          extension: segs[segs.length - 1],
          media_type: 3
        })) || {}
      if (!upload_url) {
        throw new Error('failed to upload file')
      }

      await cmsApi.upload(upload_url.replace(/^http:\/\//, 'https://').replace('-internal', ''), file, {
        headers: {
          'Content-Type': content_type
        }
      })

      form.setFieldsValue({
        [key]: public_url
      })
    } catch (err: any) {
      message.error(err?.message || err)
    }
  }

  return (
    <div className="page-manage-system">
      <Form {...formItemLayout} onFinish={onFinish} form={form}>
        <Form.Item label="页面标题" required>
          <Form.Item label="页面标题" name="display_name" noStyle rules={[{ required: true }]}>
            <Input maxLength={16} placeholder="请输入页面标题" />
          </Form.Item>

          <Popover
            content={
              <>
                <img className="guide-img" src={Guide1} />
              </>
            }
            trigger={['click']}
            placement="bottom"
          >
            <span className="guide">查看示例</span>
          </Popover>
        </Form.Item>
        <Form.Item name="ico_url" label="网站标志logo" rules={[{ required: true, message: '请上传网站标志logo' }]}>
          <div>
            {iconUrl && <img className="ico-img" src={iconUrl} />}
            <Upload
              accept=".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.webp"
              customRequest={({ onSuccess, onError, file }) => uploadFile(file as RcFile, 'ico_url')}
              name="ico_url"
              showUploadList={false}
            >
              <Button icon={<UploadOutlined />}>点击上传</Button>
            </Upload>
            <div className="form-extra">建议比例1:1，格式为png透明图片</div>
          </div>

          <Popover
            content={
              <>
                <img className="guide-img" src={Guide2} />
              </>
            }
            trigger={['click']}
            placement="bottom"
          >
            <span className="guide">查看示例</span>
          </Popover>
        </Form.Item>

        <Form.Item
          name="background_image"
          label="登录页左侧背景图"
          rules={[{ required: true, message: '登录页左侧背景图' }]}
        >
          <div>
            {backgroundImage && <img className="background-img" src={backgroundImage} />}
            <Upload
              accept=".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.webp"
              customRequest={({ onSuccess, onError, file }) => uploadFile(file as RcFile, 'background_image')}
              name="background_image"
              showUploadList={false}
            >
              <Button icon={<UploadOutlined />}>点击上传</Button>
            </Upload>
            <div className="form-extra">建议比例1.2:1</div>
          </div>

          <Popover
            content={
              <>
                <img className="guide-img" src={Guide3} />
              </>
            }
            trigger={['click']}
            placement="bottom"
          >
            <span className="guide">查看示例</span>
          </Popover>
        </Form.Item>

        <Form.Item name="logo" label="登录页logo" rules={[{ required: true, message: '登录页左侧背景图' }]}>
          <div>
            {logoImage && <img className="logo-img" src={logoImage} />}
            <Upload
              accept=".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.webp"
              customRequest={({ onSuccess, onError, file }) => uploadFile(file as RcFile, 'logo')}
              name="logo"
              showUploadList={false}
            >
              <Button icon={<UploadOutlined />}>点击上传</Button>
            </Upload>
            <div className="form-extra">建议比例4:1，格式为png透明图片</div>
          </div>

          <Popover
            content={
              <>
                <img className="guide-img" src={Guide4} />
              </>
            }
            trigger={['click']}
            placement="top"
          >
            <span className="guide">查看示例</span>
          </Popover>
        </Form.Item>

        <Form.Item label="登录页标题" required>
          <Form.Item label="登录页标题" name="welcome_line" noStyle rules={[{ required: true }]}>
            <Input maxLength={16} placeholder="请输入登录页标题" />
          </Form.Item>

          <Popover
            content={
              <>
                <img className="guide-img" src={Guide5} />
              </>
            }
            trigger={['click']}
            placement="top"
          >
            <span className="guide">查看示例</span>
          </Popover>
        </Form.Item>

        <Form.Item
          name="head_logo"
          label="网站主页头部logo"
          rules={[{ required: true, message: '请上传网站主页头部logo' }]}
        >
          <div>
            {headLogo && <img className="header-img" src={headLogo} />}
            <Upload
              accept=".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.webp"
              customRequest={({ onSuccess, onError, file }) => uploadFile(file as RcFile, 'head_logo')}
              name="head_logo"
              showUploadList={false}
            >
              <Button icon={<UploadOutlined />}>点击上传</Button>
            </Upload>
            <div className="form-extra">建议比例8:1，格式为png透明图片</div>
          </div>

          <Popover
            content={
              <>
                <img className="guide-img" src={Guide6} />
              </>
            }
            trigger={['click']}
            placement="top"
          >
            <span className="guide">查看示例</span>
          </Popover>
        </Form.Item>

        <Form.Item name="promote_image" label="网站主页左下角图片">
          <div>
            {promoteImage && <img className="header-img" src={promoteImage} />}
            <Upload
              accept=".png,.PNG,.jpg,.JPG,.jpeg,.JPEG,.webp"
              customRequest={({ onSuccess, onError, file }) => uploadFile(file as RcFile, 'promote_image')}
              name="head_logo"
              showUploadList={false}
            >
              <Button icon={<UploadOutlined />}>点击上传</Button>
            </Upload>
          </div>

          <Popover
            content={
              <>
                <img className="guide-img" src={Guide7} />
              </>
            }
            trigger={['click']}
            placement="top"
          >
            <span className="guide">查看示例</span>
          </Popover>
        </Form.Item>

        <Form.Item label="形象复刻消耗积分">
          <Form.Item name="enable_digital_human_cost" noStyle>
            <Switch checkedChildren="开启" unCheckedChildren="关闭" />
          </Form.Item>
          {enableDigitalHumanCost && (
            <Form.Item name="digital_human_cost" noStyle>
              <InputNumber min={1} maxLength={7} placeholder="请输入形象复刻消耗积分" />
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item label="创作视频按分钟收费">
          <Form.Item name="enable_creation_cost_per_minute" noStyle>
            <Switch checkedChildren="开启" unCheckedChildren="关闭" />
          </Form.Item>
          {enableCreatioCostPerMinute && (
            <Form.Item name="creation_cost_per_minute" noStyle>
              <InputNumber min={1} maxLength={7} placeholder="请输入每分钟积分收费标准" />
            </Form.Item>
          )}
        </Form.Item>

        <Form.Item label="SEO描述" name="description">
          <Input maxLength={50} placeholder="请输入SEO描述，建议3-5个描述，英文逗号分隔" />
        </Form.Item>

        <Form.Item label="SEO关键字" name="keywords">
          <Input maxLength={50} placeholder="请输入SEO关键字，建议3-5个关键字，英文逗号分隔" />
        </Form.Item>

        <Form.Item label="ICP备案号" name="icp_filing_no">
          <Input maxLength={50} placeholder="请输入ICP备案号" />
        </Form.Item>
      </Form>
      <div className="btn-submit">
        <Button type="primary" onClick={onFinish}>
          保存
        </Button>
      </div>
    </div>
  )
}

export default ManageSystem
