import './style.scss'
import { FC, memo } from 'react'
import PasswordLogin from './components/password-login'

const Login: FC = () => {
  return (
    <div className="page-login">
      <div className="page-login-main">
        <div className="title">管理员登录</div>
        <PasswordLogin />
      </div>
    </div>
  )
}

export default memo(Login)
