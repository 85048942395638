import './style.scss'
import { Button, Drawer, Input, message, Modal, Progress, Radio, Space, Spin } from 'antd'
import { RcFile } from 'antd/es/upload'
import Dragger from 'antd/es/upload/Dragger'
import axios from 'axios'
import { FC, useEffect, useRef, useState } from 'react'
import { cmsApi } from '@/api'
import * as manageApi from '@/api/manage'
import error1 from '@/assets/error1.png'
import error2 from '@/assets/error2.png'
import error3 from '@/assets/error3.png'
import error4 from '@/assets/error4.png'
import { CloudUploadOutlined, DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons'

interface IProps {
  open: boolean
  onCancel?: () => void
  onOk?: () => void
}

const CreateAvatar: FC<IProps> = (props) => {
  const { open, onCancel, onOk } = props
  const [currentStep, setCurrentStep] = useState(0)
  const [videoFile, setVideoFile] = useState<RcFile>(undefined as any)
  const [videoUrl, setVideoUrl] = useState<string>(undefined as any)
  const [videoInfo, setVideoInfo] = useState<any>({})
  const [percent, setPercent] = useState(0)
  const cancelTokenSource = useRef<any>()
  const [name, setName] = useState('')
  const [declareChecked, setDeclareChecked] = useState(false)
  const [guideModalOpen, setGuideModalOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setGuideModalOpen(!localStorage.getItem('guideRead'))
  }, [])

  useEffect(() => {
    if (open) {
      setName('')
      restartUpload()
      setDeclareChecked(false)
      showDeclare()
    }
  }, [open])

  useEffect(() => {
    if (videoFile) {
      setCurrentStep(1)
      setVideoUrl(URL.createObjectURL(videoFile))
    } else {
      setVideoUrl(undefined as any)
    }
  }, [videoFile])

  const onGuideModalClose = () => {
    setGuideModalOpen(false)
    localStorage.setItem('guideRead', '1')
  }

  const beforeVideoUpload = async (file: RcFile) => {
    return new Promise((resolve) => {
      const type = file.name.split(/\./)?.at(-1)?.toLocaleLowerCase() as string
      if (['mp4', 'mov'].includes(type)) {
        const size = file?.size || 0
        if (size / 1000 / 1000 > 500) {
          message.warning('视频大小不能大于 500MB')
          resolve(false)
        }
        const videoElement = document.createElement('video')
        videoElement.src = URL.createObjectURL(file)
        videoElement.addEventListener('loadedmetadata', () => {
          console.log('videoElement', videoElement.videoWidth)

          if (videoElement.videoWidth === 0) {
            message.warning('视频处理失败, 建议你使用h264编码格式的mp4文件')
            return resolve(false)
          }

          if (videoElement.videoWidth < 360 || videoElement.videoWidth > 4096) {
            message.warning('视频素材分辨率要求为360p～4K')
            return resolve(false)
          }

          if (videoElement.duration < 5 || videoElement.duration > 1800) {
            message.warning('视频时长要求为5秒～30分钟')
            return resolve(false)
          }
          resolve(true)
        })
      } else {
        message.warning('请上传mp4、mov格式视频文件')
        resolve(false)
      }
    })
  }

  const onDrop = async (file: RcFile) => {
    const type = file.name.split(/\./)?.at(-1)?.toLocaleLowerCase() as string
    if (!['mp4', 'mov'].includes(type)) {
      message.warning('请上传mp4、mov格式视频文件')
    }
  }

  // media_type -  0 未定义  1 视频  2 音频
  const uploadFile = async (file: RcFile, media_type: 0 | 1 | 2) => {
    try {
      const segs = (file.name || '').split(/\./)
      const { upload_url, oss_key, content_type } =
        (await cmsApi.post('upload_url', {
          extension: segs[segs.length - 1],
          media_type
        })) || {}
      if (!upload_url) {
        throw new Error('failed to upload file')
      }

      cancelTokenSource.current = axios.CancelToken.source()
      setPercent(0)
      setCurrentStep(1)

      await cmsApi.upload(upload_url.replace(/^http:\/\//, 'https://').replace('-internal', ''), file, {
        onUploadProgress: (progress) => {
          const percent = Math.round((progress.progress || 0) * 100)
          setPercent(percent)
        },
        headers: {
          'Content-Type': content_type
        },
        cancelToken: cancelTokenSource.current?.token
      })

      setCurrentStep(2)
      if (media_type === 1) {
        setVideoInfo({
          title: segs[0],
          oss_key
        })
      }
    } catch (err: any) {
      if (err?.code !== 'ERR_CANCELED') {
        message.error(err?.message || err)
      }
    }
  }

  const restartUpload = () => {
    cancelUpload()
    setCurrentStep(0)
    setPercent(0)
    setVideoFile(undefined as any)
    setVideoInfo(undefined)
  }

  const cancelUpload = () => {
    if (cancelTokenSource) {
      cancelTokenSource.current?.cancel('取消上传')
    }
  }

  const showDeclare = () => {
    Modal.confirm({
      width: 500,
      title: '使用者承诺须知',
      content: (
        <>
          <div>
            本声明将帮助您更好的在【飞影数字人】平台（下称“本平台”）使用相关工具上传和管理您的作品。您若上传作品，即视为您以充分知悉并充分接受以下内容：
          </div>
          <ul className="declare-list">
            <li>您作为使用者在本平台上传、发布的作品，应具有独立、完整的知识产权，不得侵犯他人知识产权等任何权利。</li>
            <li>
              您在使用本平台及上传、发布作品时，应当自觉遵守国家法律、法规，遵守公共秩序，尊重社会公德、社会主义制度、国家利益、公民合法权益、道德风尚和信息真实性等要求。如有违反，一经本平台发现将根据违规程度采取包括但不限于删除、下架、禁止发布内容、封禁账号等处理方式。如造成恶劣影响或涉嫌违法犯罪的，本平台将有权向有关管理机关或公安机关提交相关内容，并配合进行调查。
            </li>
            <li>
              若您上传的作品及作品中的素材（包括但不限于创意、文本、肖像、音频、图片、视频等）侵犯了任何第三方权利，本平台均有权在收到相关侵权投诉后对该被投诉的作品或用户账号依据相应规则，采取包括但不限于下架、警告、封禁账号等处理方式。
            </li>
          </ul>
        </>
      ),
      okText: '我已知晓，同意',
      cancelText: '取消',
      onOk: () => {
        setDeclareChecked(true)
      }
    })
  }

  const completeCreate = async () => {
    if (!name?.trim()) {
      return message.warning('请输入数字分身的名字')
    }

    if (!videoInfo?.oss_key) {
      return message.warning('请上传视频')
    }

    setLoading(true)
    try {
      await manageApi.addGlobalDigitalHumans({
        oss_key: videoInfo?.oss_key,
        title: name
      })
      message.success('新增成功')
      onCancel?.()
      onOk?.()
    } finally {
      setLoading(false)
    }
  }

  return (
    <Drawer
      className="create-drawer"
      open={open}
      title="新增公用数字分身"
      width={640}
      onClose={onCancel}
      footer={
        <>
          <div className="declare">
            <Radio checked={declareChecked} onClick={() => setDeclareChecked(!declareChecked)}></Radio>
            我已阅读并同意
            <label className="link" onClick={showDeclare}>
              《使用者承诺须知》
            </label>
          </div>
          <Space>
            <div className="fee"></div>
            <Button onClick={onCancel}>取消</Button>
            <Button disabled={!declareChecked} type="primary" loading={loading} onClick={completeCreate}>
              {loading ? '复刻中' : '提交'}
            </Button>
          </Space>
        </>
      }
    >
      <Spin spinning={loading} tip={'数字分身复刻中'}>
        <div className="main">
          <div className="form-item">
            <div className="title">数字分身名称</div>
            <Input
              maxLength={20}
              placeholder="请输入数字分身名称"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="form-item">
            <div className="title">
              上传视频
              <ExclamationCircleFilled
                onClick={() => {
                  setGuideModalOpen(true)
                }}
              />
            </div>

            <div className="warning">
              <h4>视频要求</h4>
              <div className="desc">
                <div>
                  <label className="label">视频方向：</label>
                  <label>横向或纵向</label>
                </div>
                <div>
                  <label className="label">文件格式：</label>
                  <label>mp4、mov</label>
                </div>
                <div>
                  <label className="label">视频时长：</label>
                  <label>5秒~30分钟</label>
                </div>
                <div>
                  <label className="label">分辨率：</label>
                  <label>360p~4K</label>
                </div>
                <div>
                  <label className="label">文件大小：</label>
                  <label>小于500MB</label>
                </div>
              </div>
            </div>
            {currentStep === 0 && (
              <>
                <div className="content">
                  <Dragger
                    accept=".mp4,.mov"
                    showUploadList={false}
                    beforeUpload={async (file) => {
                      const flag = (await beforeVideoUpload(file)) as any
                      if (flag) {
                        setVideoFile(file)
                        uploadFile(file, 1)
                      }
                      return flag
                    }}
                    onDrop={(e) => onDrop(e.dataTransfer.files?.[0] as any)}
                  >
                    <p className="ant-upload-drag-icon">
                      <CloudUploadOutlined />
                    </p>
                    <p className="ant-upload-text">请上传一段视频，作为驱动数字人的底版视频</p>
                    <p className="ant-upload-hint">将文件拖到此处，或点击此区域上传</p>
                  </Dragger>
                </div>
              </>
            )}
            {currentStep === 1 && (
              <div className="step-progress">
                <div className="step-progress-content">
                  <div className="percent">{percent}%</div>
                  <Progress percent={percent} showInfo={false} />
                  <div className="tips">视频上传中</div>
                </div>
                <div className="btns">
                  <Button onClick={restartUpload}>取消</Button>
                </div>
              </div>
            )}
            {currentStep === 2 && (
              <div className="step-view">
                <div className="step-view-box video">
                  <video controls src={videoUrl} />
                  <div className="trash" onClick={restartUpload}>
                    <DeleteOutlined />
                  </div>
                </div>
                <div className="btns">
                  <div onClick={restartUpload}>重新上传</div>
                </div>
              </div>
            )}
          </div>

          <Modal
            title="视频要求"
            open={guideModalOpen}
            footer={
              <Button type="primary" onClick={onGuideModalClose}>
                知道了
              </Button>
            }
            closeIcon={false}
            width={580}
            onCancel={onGuideModalClose}
          >
            <div className="modal-guide">
              <ul>
                <li>
                  <label>1.</label>不要使用有多人的视频
                </li>
                <li>
                  <label>2.</label>确保人脸不要太小，建议人脸宽度占整体画面宽度的1/4以上
                </li>
                <li>
                  <label>3.</label>人脸不要太大，确保整张人脸都在屏幕区域内，人脸不要出屏幕
                </li>
                <li>
                  <label>4.</label>确保面部特征没有被遮挡，并努力让面部清晰可见
                </li>
                <li>
                  <label>5.</label>上传的视频分辨率不应低于360p，也不应超过3840p，建议分辨率为720p或1080p
                </li>
                <li>
                  <label>6.</label>视频长度不应少于5秒，不超过30分钟
                </li>
              </ul>
              <div className="guide-error">
                <div className="title-error">错误示例</div>
                <div className="error-list">
                  <div className="item">
                    <img className="img1" src={error1} />
                    <div>多张人脸</div>
                  </div>
                  <div className="item">
                    <img className="img2" src={error2} />
                    <div>人脸太大</div>
                  </div>
                  <div className="item">
                    <img className="img3" src={error3} />
                    <div>脸部遮挡</div>
                  </div>
                  <div className="item">
                    <img className="img3" src={error4} />
                    <div>检测不到人脸</div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </Spin>
    </Drawer>
  )
}

export default CreateAvatar
