import { Navigate, useRoutes } from 'react-router-dom'
import LayoutIndex from '@/components/Layout'
import AccountOverview from '@/views/account/overview'
import Login from '@/views/login'
import ManageAccount from '@/views/manage/account'
import ManageMaterial from '@/views/manage/material'
import ManagePoint from '@/views/manage/point'
import ManageSystem from '@/views/manage/system'
import ManageWorks from '@/views/manage/works'
import ResetPassword from '@/views/account/resetPassword'

export const rootRouter = [
  {
    path: '/login',
    element: <Login />,
    meta: {
      requiresAuth: false,
      title: '登录页'
    }
  },
  {
    element: <LayoutIndex />,
    children: [
      {
        path: '/account/overview',
        element: <AccountOverview />,
        meta: {
          requiresAuth: true,
          title: '账户总览'
        }
      },
      {
        path: '/manage/account',
        element: <ManageAccount />,
        meta: {
          requiresAuth: true,
          title: '用户账号管理'
        }
      },
      {
        path: '/manage/works',
        element: <ManageWorks />,
        meta: {
          requiresAuth: true,
          title: '用户作品管理'
        }
      },

      {
        path: '/manage/point',
        element: <ManagePoint />,
        meta: {
          requiresAuth: true,
          title: '用户积分明细'
        }
      },
      {
        path: '/manage/material',
        element: <ManageMaterial />,
        meta: {
          requiresAuth: true,
          title: '公用素材管理'
        }
      },
      {
        path: '/manage/system',
        element: <ManageSystem />,
        meta: {
          requiresAuth: true,
          title: '系统设置'
        }
      },
      {
        path: '/reset/password',
        element: <ResetPassword />,
        meta: {
          requiresAuth: true,
          title: '修改密码',
          headerShow: true
        }
      }
    ]
  },
  {
    path: '*',
    element: <Navigate to="/account/overview" />
  }
]

const Router = () => {
  const routes = useRoutes(rootRouter)
  return routes
}

export default Router
