import { cmsApi } from '@/api'

export const getGlobalDigitalHumans = () => {
  return cmsApi.get('global_digital_humans')
}

export const addGlobalDigitalHumans = (data: any) => {
  return cmsApi.post('global_digital_humans', data)
}

export const updateGlobalDigitalHumans = (id: string | number, data: any) => {
  return cmsApi.patch(`global_digital_humans/${id}`, data)
}

export const deleteGlobalDigitalHumans = (id: string | number) => {
  return cmsApi.delete(`global_digital_humans/${id}`)
}

export const getCreditsLogs = (data: any) => {
  return cmsApi.get('admin_credits_logs', data)
}

export const getUserCreditsLogs = (data: any) => {
  return cmsApi.get('user_credits_logs', data)
}

export const getUserCreations = (data: any) => {
  return cmsApi.get(`user_creations`, data)
}

export const modifySystemConfig = (name: string, data: any) => {
  return cmsApi.patch(`sys_config/${name}`, data)
}
