import { Menu } from 'antd'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { AppstoreOutlined, TeamOutlined } from '@ant-design/icons'

import type { MenuProps } from 'antd'
type MenuItem = Required<MenuProps>['items'][number]

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type
  } as MenuItem
}

const items: MenuProps['items'] = [
  getItem('我的账户', 'account', <TeamOutlined />, [getItem('账户总览', '/account/overview')]),
  getItem('管理中心', 'manage', <AppstoreOutlined />, [
    getItem('用户账户管理', '/manage/account'),
    getItem('用户作品管理', '/manage/works'),
    getItem('用户积分明细', '/manage/point'),
    getItem('公用素材管理', '/manage/material'),
    getItem('系统设置', '/manage/system')
  ])
]

const LayoutMenu: React.FC = () => {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  console.log(pathname)

  const onClick: MenuProps['onClick'] = (e) => {
    navigate(e.key)
  }

  return (
    <Menu
      onClick={onClick}
      style={{ width: 236 }}
      defaultOpenKeys={['account', 'manage']}
      selectedKeys={[pathname]}
      mode="inline"
      items={items}
    />
  )
}

export default LayoutMenu
