import './style.scss'
import { Button, DatePicker, Form, Input, message, Modal, Radio, Table } from 'antd'
import dayjs from 'dayjs'
import { FC, useEffect, useRef, useState } from 'react'
import * as AccountApi from '@/api/account'
import { PageStore } from '@/global-states'
import { layout, searchLayout, validateMessages } from '@/libs/config'

const ManageAccount: FC = () => {
  const { defaultPageSize } = PageStore
  const [searchForm] = Form.useForm()
  const searchs = useRef<any>()
  const [addForm] = Form.useForm()
  const [allotForm] = Form.useForm()
  const [passwordForm] = Form.useForm()
  const [editForm] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<any>({})
  const [creditsInfo, setCreditsInfo] = useState<any>()
  const [addModalOpen, setAddModalOpen] = useState(false)
  const [allotModalOpen, setAllotModalOpen] = useState(false)
  const [passwordModalOpen, setPasswordModalOpen] = useState(false)
  const [editrModalOpen, setEditModalOpen] = useState(false)
  const page = useRef<number>(1)
  const pageSize = useRef<number>(defaultPageSize)
  const [sorter, setSorter] = useState<{ orderBy: string; orderType: 'asc' | 'desc' | null }>({
    orderBy: '',
    orderType: null
  })

  useEffect(() => {
    getData()
    getCreditsInfo()
  }, [sorter])

  const getData = async () => {
    const res = await AccountApi.getUserList({
      page: page.current,
      page_size: pageSize.current,
      ...(searchs.current || {}),
      order_by: sorter.orderBy, // 添加排序字段
      order_type: sorter.orderType // 添加排序顺序
    })

    const userCredits: any = await AccountApi.getUserCredits({
      user_id_list: res.users?.map((l: any) => l.uid)
    })
    setData({
      ...res,
      users: res.users?.map((user: any) => ({
        ...user,
        credits: userCredits?.user_credits_list.find((u: any) => u.user_id === user.uid)?.credits || 0
      }))
    })
  }

  const getCreditsInfo = async () => {
    const res = await AccountApi.getCreditsInfo()
    setCreditsInfo(res)
  }

  const onPageChange = (currentPage: number, currentPageSize: number) => {
    page.current = currentPage
    pageSize.current = currentPageSize
    getData()
  }

  const onSearchFormChange = (e: any) => {
    e.type === 'click' && onSearch()
  }

  const onSearch = () => {
    searchs.current = { ...searchForm.getFieldsValue() }
    getData()
  }

  const submitAddAccount = async () => {
    await addForm.validateFields()
    const form = addForm.getFieldsValue()
    setLoading(true)
    try {
      await AccountApi.createAccount({
        ...form,
        expired_at: +(new Date(form.expired_at).getTime() / 1000).toFixed(0)
      })
      setAddModalOpen(false)
      message.success('新增成功')
      getData()
      getCreditsInfo()
    } finally {
      setLoading(false)
    }
  }

  const allotPoint = async () => {
    await allotForm.validateFields()
    const form = allotForm.getFieldsValue()
    setLoading(true)
    try {
      await AccountApi.assignCredits({
        ...form,
        credits: +form.credits
      })
      setAllotModalOpen(false)
      message.success('分配成功')
      getCreditsInfo()
      getData()
    } finally {
      setLoading(false)
    }
  }

  const resetPassword = async () => {
    await passwordForm.validateFields()
    const form = passwordForm.getFieldsValue()
    if (form.password !== form.newPassword) {
      return message.warning('两次密码输入不一致！')
    }
    setLoading(true)
    try {
      await AccountApi.passwordEdit({
        uid: form.uid,
        newPassword: form.newPassword
      })
      setPasswordModalOpen(false)
      message.success('密码修改成功')
      getData()
    } finally {
      setLoading(false)
    }
  }

  const editUser = async () => {
    await editForm.validateFields()
    const form = editForm.getFieldsValue()
    await AccountApi.userEdit({
      uid: form.uid,
      nickname: form.nickname,
      status: form.status,
      expired_at: +(new Date(form.expired_at).getTime() / 1000).toFixed(0)
    })
    message.success('修改成功')
    setEditModalOpen(false)
    getData()
  }

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.order) {
      // 如果有排序，则更新排序状态
      setSorter({
        orderBy: sorter.field,
        orderType: sorter.order === 'ascend' ? 'asc' : 'desc'
      })
    } else {
      // 如果没有排序，则重置排序状态
      setSorter({
        orderBy: '',
        orderType: null
      })
    }
  }

  return (
    <div className="page-common page-manage-works">
      <div className="m-title">
        <div>用户管理</div>
      </div>
      <div className="m-detail">
        <div className="m-search">
          <div className="right">
            <Form className="search-form" {...searchLayout} form={searchForm} validateMessages={validateMessages}>
              <Form.Item name="phone" label={null}>
                <Input placeholder="输入账号搜索" allowClear onPressEnter={onSearch} onChange={onSearchFormChange} />
              </Form.Item>
            </Form>

            <Button type="primary" onClick={onSearch}>
              查询
            </Button>
            <Button
              type="primary"
              onClick={() => {
                addForm.resetFields()
                setAddModalOpen(true)
              }}
            >
              新增账号
            </Button>
          </div>
        </div>
        <Table
          className="m-table"
          pagination={{
            showSizeChanger: true,
            defaultPageSize,
            total: data.num_pages,
            onChange: onPageChange,
            showTotal: (total) => `共 ${total} 条`
          }}
          scroll={{ y: 0 }}
          columns={[
            {
              title: '序号',
              dataIndex: 'index',
              width: 80,
              render: (value: any, data: any, index: number) => <div>{index + 1}</div>
            },
            {
              title: '账号',
              dataIndex: 'phone',
              width: 180
            },
            {
              title: '昵称',
              dataIndex: 'nickname',
              width: 200
            },
            {
              title: '创建时间',
              dataIndex: 'created_at',
              width: 200,
              render: (created_at: number) => dayjs(created_at * 1000).format('YYYY-MM-DD HH:mm:ss')
            },
            {
              title: '过期时间',
              dataIndex: 'expired_at',
              width: 200,
              sorter: true, // 启用排序
              render: (expired_at: number) =>
                expired_at ? (
                  <div className={expired_at * 1000 <= Date.now() ? 'red' : ''}>
                    {dayjs(expired_at * 1000).format('YYYY-MM-DD HH:mm:ss')}
                  </div>
                ) : (
                  ''
                )
            },
            {
              title: '积分数量',
              dataIndex: 'credits',
              width: 160
            },
            {
              title: '状态',
              dataIndex: 'status',
              width: 100,
              render: (status: number) => (status ? '有效' : '无效')
            },
            {
              title: '操作',
              width: 200,
              render: (_, user) => {
                return (
                  <div className="m-op">
                    <a
                      onClick={() => {
                        allotForm.setFieldsValue({
                          ...user,
                          credits: ''
                        })
                        setAllotModalOpen(true)
                      }}
                    >
                      分配积分
                    </a>
                    <a
                      onClick={() => {
                        passwordForm.setFieldsValue(user)
                        setPasswordModalOpen(true)
                      }}
                    >
                      重置密码
                    </a>
                    <a
                      onClick={() => {
                        editForm.setFieldsValue({
                          ...user,
                          status: user.status === 1 ? 1 : 0,
                          expired_at: user.expired_at ? dayjs(user.expired_at * 1000) : ''
                        })
                        setEditModalOpen(true)
                      }}
                    >
                      编辑
                    </a>
                  </div>
                )
              }
            }
          ]}
          locale={{
            emptyText: <div className="m-empty">暂无记录</div>
          }}
          dataSource={data?.users}
          onChange={handleTableChange}
        ></Table>
      </div>

      <Modal
        title="新增账号"
        open={addModalOpen}
        onCancel={() => {
          addForm.resetFields()
          setAddModalOpen(false)
        }}
        onOk={submitAddAccount}
        confirmLoading={loading}
      >
        <Form {...layout} form={addForm} name="control-hooks" validateMessages={validateMessages}>
          <Form.Item
            name="phone"
            label="账号"
            rules={[
              { required: true },
              {
                pattern: /^\d{11}$/,
                message: '请输入 11 位手机号'
              }
            ]}
          >
            <Input placeholder="11 位手机号" />
          </Form.Item>
          <Form.Item
            name="password"
            label="密码"
            rules={[
              { required: true },
              {
                pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/,
                message: '至少 6 位字符，且必须同时包含数字和字母'
              }
            ]}
          >
            <Input placeholder="至少 6 位字符，且必须同时包含数字和字母" />
          </Form.Item>
          <Form.Item name="expired_at" label="过期时间" rules={[{ required: false }]}>
            <DatePicker placeholder="请选择过期时间" picker="date" showTime style={{ width: '100%' }} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="分配积分"
        open={!!allotModalOpen}
        onCancel={() => {
          allotForm.resetFields()
          setAllotModalOpen(false)
        }}
        onOk={allotPoint}
        confirmLoading={loading}
      >
        <Form {...layout} form={allotForm} name="control-hooks" validateMessages={validateMessages}>
          <Form.Item label="可分配积分">
            <div>{creditsInfo?.credits_remained || 0}</div>
          </Form.Item>
          <Form.Item label="分配给" name="phone">
            {allotForm.getFieldValue('phone')}
          </Form.Item>
          <Form.Item name="credits" label="分配积分" rules={[{ required: true }]}>
            <Input
              type="number"
              placeholder="请输入分配积分, 如果是回收则填负数"
              onKeyDown={(e) => {
                if (e.code === 'Period') {
                  e.preventDefault()
                }
              }}
              onChange={(e) => {
                const val = e.target.value
                addForm.setFieldsValue({
                  credits: val ? parseInt(e.target.value) : val
                })
              }}
            />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="重置密码"
        open={!!passwordModalOpen}
        onCancel={() => {
          passwordForm.resetFields()
          setPasswordModalOpen(false)
        }}
        onOk={resetPassword}
        confirmLoading={loading}
      >
        <Form {...layout} form={passwordForm} name="control-hooks" validateMessages={validateMessages}>
          <Form.Item name="uid" label="账号">
            {passwordForm.getFieldValue('phone')}
          </Form.Item>
          <Form.Item
            name="password"
            label="新密码"
            rules={[
              { required: true },
              {
                pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/,
                message: '至少 6 位字符，且必须同时包含数字和字母'
              }
            ]}
          >
            <Input.Password placeholder="至少 6 位字符，且必须同时包含数字和字母" />
          </Form.Item>
          <Form.Item
            name="newPassword"
            label="再次确认"
            rules={[
              { required: true },
              {
                pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/,
                message: '至少 6 位字符，且必须同时包含数字和字母'
              }
            ]}
          >
            <Input.Password placeholder="至少 6 位字符，且必须同时包含数字和字母" />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="用户信息编辑"
        open={!!editrModalOpen}
        onCancel={() => {
          setEditModalOpen(false)
        }}
        onOk={editUser}
        confirmLoading={loading}
      >
        <Form {...layout} form={editForm} name="control-hooks" validateMessages={validateMessages}>
          <Form.Item name="uid" label="账号">
            {editForm.getFieldValue('phone')}
          </Form.Item>
          <Form.Item name="nickname" label="昵称">
            <Input placeholder="昵称" />
          </Form.Item>
          <Form.Item name="expired_at" label="过期时间" rules={[{ required: false }]}>
            <DatePicker placeholder="请选择过期时间" picker="date" showTime style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item name="status" label="状态">
            <Radio.Group>
              <Radio value={1}> 启用 </Radio>
              <Radio value={0} className="red">
                停用
              </Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default ManageAccount
