export const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}

export const searchLayout = {
  wrapperCol: { span: 24 }
}

export const validateMessages = {
  required: '请输入${label}'
}
