import './style.scss'
import { Button, Form, Input, Table } from 'antd'
import dayjs from 'dayjs'
import { FC, useEffect, useRef, useState } from 'react'
import * as Api from '@/api/account'
import * as ManageApi from '@/api/manage'
import { PageStore } from '@/global-states'
import { searchLayout, validateMessages } from '@/libs/config'

const ManagePoint: FC = () => {
  const { defaultPageSize } = PageStore
  const [data, setData] = useState<any>({})
  const [creditsInfo, setCreditsInfo] = useState<any>()
  const [searchForm] = Form.useForm()
  const searchs = useRef<any>()
  const page = useRef<number>(0)
  const pageSize = useRef<number>(defaultPageSize)

  useEffect(() => {
    getData()
    getCreditsInfo()
  }, [])

  const getCreditsInfo = async () => {
    const res = await Api.getCreditsInfo()
    setCreditsInfo(res)
  }

  const getData = async () => {
    const res = await ManageApi.getUserCreditsLogs({
      page: page.current,
      size: pageSize.current,
      ...(searchs.current || {})
    })
    setData(res || {})
  }

  const onPageChange = (currentPage: number, currentPageSize: number) => {
    page.current = currentPage - 1
    pageSize.current = currentPageSize
    getData()
  }

  const onSearchFormChange = (e: any) => {
    e.type === 'click' && onSearch()
  }

  const onSearch = () => {
    searchs.current = { ...searchForm.getFieldsValue() }
    getData()
  }

  return (
    <div className="page-common page-account-overview">
      <div className="m-info">
        <div className="m-title">用户积分明细</div>
        <div className="info-point">
          <div className="item">
            <span className="text">已消耗分配积分</span>
            <span className="num">{creditsInfo?.credits_spent || (creditsInfo ? 0 : '-')}</span>
          </div>
        </div>
      </div>
      <div className="m-detail">
        <div className="m-search">
          <div>已分配积分消耗明细</div>
          <div className="right">
            <Form className="search-form" {...searchLayout} form={searchForm} validateMessages={validateMessages}>
              <Form.Item name="phone" label={null}>
                <Input placeholder="输入账号搜索" allowClear onPressEnter={onSearch} onChange={onSearchFormChange} />
              </Form.Item>
            </Form>

            <Button type="primary" onClick={onSearch}>
              查询
            </Button>
          </div>
        </div>
        <Table
          className="m-table"
          pagination={{
            showSizeChanger: true,
            defaultPageSize,
            total: data.total,
            onChange: onPageChange,
            showTotal: (total) => `共 ${total} 条`
          }}
          scroll={{ y: 0 }}
          columns={[
            {
              title: '序号',
              dataIndex: 'index',
              width: 80,
              render: (value: any, data: any, index: number) => <div>{index + 1}</div>
            },
            {
              title: '账号',
              dataIndex: 'phone',
              width: 300
            },
            {
              title: '类型',
              dataIndex: 'description',
              width: 400
            },
            {
              title: '积分变动数量',
              dataIndex: 'credits_delta',
              width: 200,
              render: (credits_delta: number) => (credits_delta >= 0 ? `+${credits_delta}` : credits_delta)
            },
            {
              title: '变动后积分余额',
              dataIndex: 'credits_remained',
              width: 200,
              render: (credits_remained: number) => credits_remained || 0
            },
            {
              title: '时间',
              dataIndex: 'create_time',
              width: 300,
              render: (create_time: number) => dayjs(create_time * 1000).format('YYYY-MM-DD HH:mm:ss')
            }
          ]}
          locale={{
            emptyText: <div className="m-empty">暂无记录</div>
          }}
          dataSource={data.list}
        ></Table>
      </div>
    </div>
  )
}

export default ManagePoint
