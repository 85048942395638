import resso from 'resso'

interface ILoginStore {
  phone: string
  loginType: string
  agreementChecked: boolean
  rememberAccount: boolean
}

const getInitialState = (): ILoginStore => {
  return {
    phone: '',
    loginType: 'password',
    agreementChecked: true,
    rememberAccount: false
  }
}

export const LoginStore = resso({
  ...getInitialState()
})
