import { ConfigProvider } from 'antd'
import zhCN from 'antd/lib/locale/zh_CN'
import { Suspense, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import AuthRouter from '@/routers/authRouter'
import Router from '@/routers/index'
import { UserStore } from './global-states'

const App = () => {
  const { name } = UserStore.config

  useEffect(() => {
    initPage()
  }, [])

  const initPage = async () => {
    UserStore.getSystemConfig()
  }

  if (!name) return null

  return (
    <BrowserRouter>
      <ConfigProvider
        locale={zhCN}
        theme={{
          token: {
            colorPrimary: '#7559ff'
          },
          components: {
            Table: {
              cellPaddingBlock: 12
            }
          }
        }}
      >
        <AuthRouter>
          <Suspense>
            <Router />
          </Suspense>
        </AuthRouter>
      </ConfigProvider>
    </BrowserRouter>
  )
}
export default App
