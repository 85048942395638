import './style.scss'
import { Button, Dropdown, Input, message } from 'antd'
import { FC, useEffect, useState } from 'react'
import * as Api from '@/api/manage'
import { Ellipsis, Play } from '@/assets/svg'
import CreateAvatar from '@/components/CreateAvatar'
import VideoModal from '@/components/VideoModal'
import { urlSource } from '@/libs/util'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'

const ManageMaterial: FC = () => {
  const [editId, setEditId] = useState<any>()
  const [preview, setPreview] = useState<any>()
  const [openId, setOpenId] = useState<any>()
  const [globalData, setGlobalData] = useState<any[]>([])
  const [createModalOpen, setCreateModalOpen] = useState(false)

  useEffect(() => {
    getGlobalData()
  }, [])

  const getGlobalData = async () => {
    const res = await Api.getGlobalDigitalHumans()
    setGlobalData(res.list || [])
  }

  const previewVideo = (d: any) => {
    setPreview({
      url: d.video_url,
      title: d.title,
      id: d.id
    })
  }

  const onGlobalValueChange = (e: any) => {
    setGlobalData(
      globalData.map((d) => {
        return d.id === editId
          ? {
              ...d,
              title: e.target.value
            }
          : d
      })
    )
  }

  const saveChange = async (d: any) => {
    setEditId('')
    if (d.title.trim()) {
      Api.updateGlobalDigitalHumans(d.id, {
        title: d.title
      })
    } else {
      getGlobalData()
    }
  }

  const renameVideo = (id: any, e: any) => {
    e.stopPropagation()
    setOpenId(undefined)
    setEditId(id)
  }

  const deleteVideo = async (id: any, e: any) => {
    e.stopPropagation()
    setOpenId(undefined)
    await Api.deleteGlobalDigitalHumans(id)
    getGlobalData()
    message.success('删除成功')
  }

  const toCreateAvatar = () => {
    setCreateModalOpen(true)
  }

  const onCreateSuccess = () => {
    getGlobalData()
  }

  return (
    <div className="page-common page-manage-material">
      <>
        <div className="m-search">
          <div className="text">公用数字分身{globalData.length > 0 ? `（${globalData.length}）` : null}</div>
          <div className="right">
            <Button type="primary" onClick={toCreateAvatar}>
              新增
            </Button>
          </div>
        </div>
        {!!globalData?.length && (
          <div className="page-container">
            {globalData.map((d) => (
              <div className={`list-box ${openId === d.id ? 'hovered' : ''} `} key={d.id}>
                <div className="box-main" onClick={previewVideo.bind(this, d)}>
                  <div
                    className="bg"
                    style={{
                      backgroundImage: `url(${urlSource(d.video_url, 'video')})`
                    }}
                  ></div>
                  <div className="play">
                    <Play />
                  </div>
                  <div className="op">
                    <Dropdown
                      menu={{
                        items: [
                          {
                            key: '1',
                            label: (
                              <div className="dropdown-list" onClick={renameVideo.bind(this, d.id)}>
                                <EditOutlined />
                                重命名
                              </div>
                            )
                          },
                          {
                            key: '2',
                            label: (
                              <div className="dropdown-list" onClick={deleteVideo.bind(this, d.id)}>
                                <DeleteOutlined />
                                删除
                              </div>
                            )
                          }
                        ]
                      }}
                      placement="bottom"
                      open={openId === d.id}
                      onOpenChange={(open: boolean) => setOpenId(open ? d.id : undefined)}
                    >
                      <div className="btn" onClick={(e) => e.stopPropagation()}>
                        <Ellipsis />
                      </div>
                    </Dropdown>
                  </div>
                  <div className="photo">
                    <img src={urlSource(d.video_url, 'video')} />
                  </div>
                </div>
                <div className="bottom">
                  {editId === d.id ? (
                    <Input
                      value={d.title}
                      autoFocus
                      onChange={onGlobalValueChange}
                      onBlur={saveChange.bind(this, d)}
                      onPressEnter={saveChange.bind(this, d)}
                    />
                  ) : (
                    <div className="box-title">{d.title}</div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </>

      <VideoModal preview={preview} onCancel={() => setPreview(undefined)} btnsShow={true} />

      <CreateAvatar open={createModalOpen} onCancel={() => setCreateModalOpen(false)} onOk={onCreateSuccess} />
    </div>
  )
}

export default ManageMaterial
