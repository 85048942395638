import { Button, Form, Input, Table } from 'antd'
import dayjs from 'dayjs'
import { FC, useEffect, useRef, useState } from 'react'
import * as ManageApi from '@/api/manage'
import VideoModal from '@/components/VideoModal'
import { PageStore } from '@/global-states'
import { searchLayout, validateMessages } from '@/libs/config'

const ManagePoint: FC = () => {
  const { defaultPageSize } = PageStore
  const [data, setData] = useState<any>({})
  const [searchForm] = Form.useForm()
  const searchs = useRef<any>()
  const page = useRef<number>(0)
  const pageSize = useRef<number>(defaultPageSize)
  const [preview, setPreview] = useState<any>()

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    const res = await ManageApi.getUserCreations({
      page: page.current,
      size: pageSize.current,
      ...(searchs.current || {})
    })
    setData(res || {})
  }

  const onPageChange = (currentPage: number, currentPageSize: number) => {
    page.current = currentPage - 1
    pageSize.current = currentPageSize
    getData()
  }

  const onSearchFormChange = (e: any) => {
    e.type === 'click' && onSearch()
  }

  const onSearch = () => {
    searchs.current = { ...searchForm.getFieldsValue() }
    getData()
  }

  return (
    <div className="page-common page-account-overview">
      <div className="m-title">
        <div>用户作品管理</div>
      </div>
      <div className="m-detail">
        <div className="m-search">
          <div className="right">
            <Form className="search-form" {...searchLayout} form={searchForm} validateMessages={validateMessages}>
              <Form.Item name="phone" label={null}>
                <Input placeholder="输入账号搜索" allowClear onPressEnter={onSearch} onChange={onSearchFormChange} />
              </Form.Item>
            </Form>
            <Button type="primary" onClick={onSearch}>
              查询
            </Button>
          </div>
        </div>
        <Table
          className="m-table"
          pagination={{
            showSizeChanger: true,
            defaultPageSize,
            total: data.total,
            onChange: onPageChange,
            showTotal: (total) => `共 ${total} 条`
          }}
          scroll={{ y: 0 }}
          columns={[
            {
              title: '序号',
              dataIndex: 'index',
              width: 80,
              render: (value: any, data: any, index: number) => <div>{index + 1}</div>
            },
            {
              title: '账号',
              dataIndex: 'phone',
              width: 300
            },
            {
              title: '作品名称',
              dataIndex: 'name',
              width: 400
            },
            {
              title: '创建时间',
              dataIndex: 'create_time',
              width: 300,
              render: (create_time: number) => dayjs(create_time * 1000).format('YYYY-MM-DD HH:mm:ss')
            },
            {
              title: '操作',
              width: 100,
              render: (_, data) => {
                return (
                  <div className="m-op">
                    <a
                      onClick={() =>
                        setPreview({
                          title: data.name,
                          url: data.url
                        })
                      }
                    >
                      查看视频
                    </a>
                  </div>
                )
              }
            }
          ]}
          locale={{
            emptyText: <div className="m-empty">暂无记录</div>
          }}
          dataSource={data.list}
        ></Table>
      </div>

      <VideoModal preview={preview} onCancel={() => setPreview(undefined)} />
    </div>
  )
}

export default ManagePoint
