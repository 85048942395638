import './style.scss'
import { Dropdown, Form, Input, MenuProps, message, Modal } from 'antd'
import { FC, memo, useState } from 'react'
import * as AccountApi from '@/api/account'
import { Loginout } from '@/assets/svg/loginout'
import { Password } from '@/assets/svg/password'
import { UserStore } from '@/global-states'
import { layout, validateMessages } from '@/libs/config'

interface IProps {
  title?: string
}

const LayoutHeader: FC<IProps> = () => {
  const { config } = UserStore
  const [passwordModalOpen, setPasswordModalOpen] = useState(false)
  const [passwordForm] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const loginOut = () => {
    UserStore.loginOut()
  }

  const items: MenuProps['items'] = [
    {
      key: '5',
      label: (
        <a href="javascript:void(0)" onClick={() => setPasswordModalOpen(true)}>
          <Password />
          修改密码
        </a>
      )
    },
    {
      key: '4',
      label: (
        <a target="_blank" rel="noopener noreferrer" onClick={loginOut}>
          <Loginout />
          退出登陆
        </a>
      )
    }
  ]

  const resetPassword = async () => {
    await passwordForm.validateFields()
    const form = passwordForm.getFieldsValue()
    if (form.password !== form.newPassword) {
      return message.warning('两次密码输入不一致！')
    }
    setLoading(true)
    try {
      await AccountApi.adminPasswordEdit({
        oldPassword: form.oldPassword,
        newPassword: form.newPassword,
        system: config.name
      })
      setPasswordModalOpen(false)
      message.success('密码修改成功，请用新密码重新登陆')

      setTimeout(() => {
        loginOut()
      }, 1000)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="layout-header">
      <div className="title">管理后台</div>
      <div className="right">
        <Dropdown
          className="header-menu"
          menu={{ items }}
          placement="bottomRight"
          arrow
          overlayClassName={'header-menu-item'}
        >
          <div className="user">AI</div>
        </Dropdown>
      </div>

      <Modal
        title="重置密码"
        open={!!passwordModalOpen}
        onCancel={() => {
          passwordForm.resetFields()
          setPasswordModalOpen(false)
        }}
        onOk={resetPassword}
        confirmLoading={loading}
      >
        <Form {...layout} form={passwordForm} name="control-hooks" validateMessages={validateMessages}>
          <Form.Item name="oldPassword" label="老密码" rules={[{ required: true }]}>
            <Input.Password placeholder="请输入老密码" />
          </Form.Item>
          <Form.Item
            name="password"
            label="新密码"
            rules={[
              { required: true },
              {
                pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/,
                message: '至少 6 位字符，且必须同时包含数字和字母'
              }
            ]}
          >
            <Input.Password placeholder="至少 6 位字符，且必须同时包含数字和字母" />
          </Form.Item>
          <Form.Item
            name="newPassword"
            label="再次确认"
            rules={[
              { required: true },
              {
                pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/,
                message: '至少 6 位字符，且必须同时包含数字和字母'
              }
            ]}
          >
            <Input.Password placeholder="至少 6 位字符，且必须同时包含数字和字母" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default memo(LayoutHeader)
